.cp-explorer-filter-expanded {
  display: flex;
  margin-top: 2%;
  width: 100%;
  gap: 20px;
}

.cp-explorer-filter-expanded-container {
  flex: 1;
}

.cp-explorer-filter-expanded-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Existing styles... */

.dropdown-options {
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 5px;
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.dropdown-options::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.dropdown-option-checkbox {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.dropdown-option-checkbox:hover {
  background-color: #f9f9f9;
}

.dropdown-option-checkbox input[type="checkbox"] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.dropdown-option-checkbox label {
  font-size: 14px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-icon {
  font-size: 14px;
  width: 16px;
  height: 18px;
  margin-right: 10px;
}

/* Status filter option styles with icon color matching label background */
.dropdown-option-checkbox[data-status="Active"] label {
  background-color: #16a086;
  color: #fff
}

.dropdown-option-checkbox[data-status="Active"] .status-icon {
  color: #16a086;
}

.dropdown-option-checkbox[data-status="Dormant"] label {
  background-color: #026aa2;
  color: #fff
}

.dropdown-option-checkbox[data-status="Dormant"] .status-icon {
  color: #026aa2;
}

.dropdown-option-checkbox[data-status="Dissolved"] label {
  background-color: #d27b74;
  color: #fff
}

.dropdown-option-checkbox[data-status="Dissolved"] .status-icon {
  color: #d27b74;
}

.dropdown-option-checkbox[data-status="In Liquidation"] label {
  background-color: #9b7cea;
  color: #fff
}

.dropdown-option-checkbox[data-status="In Liquidation"] .status-icon {
  color: #9b7cea;
}

.dropdown-option-checkbox[data-status="In Administration"] label {
  background-color: #d58873;
  color: #fff
}

.dropdown-option-checkbox[data-status="In Administration"] .status-icon {
  color: #d58873;
}

.dropdown-option-checkbox[data-status="In Receivership"] label {
  background-color: #868caa;
  color: #fff
}

.dropdown-option-checkbox[data-status]:hover label {
  filter: brightness(0.95);
  color: #fff
}

.option-header {
  font-weight: bold;
  padding: 12px 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.cp-explorer-filter-expanded-container {
  position: relative;
  margin-bottom: 8px;
}

.cp-explorer-filter-expanded-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cp-explorer-filter-expanded-dropdown {
  background: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.selected-option {
  font-weight: normal;
  color: #fff;
  background-color: #0380c7;
  padding: 2px 8px;
  border-radius: 4px;
  margin: 0 4px;
  display: inline-block;
  position: relative;
}

.selected-option .remove-option {
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff4444;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;
}

.selected-option:hover .remove-option {
  display: flex;
}

.remove-option:hover {
  background-color: #cc0000;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.apply-filters-button {
  background-color: #4a7aff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.apply-filters-button:hover {
  background-color: #3a6ae0;
}

/* Add styles for search results containers */
.location-search-results,
.sic-code-search-results {
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

/* Style the scrollbar for better visibility */
.location-search-results::-webkit-scrollbar,
.sic-code-search-results::-webkit-scrollbar {
  width: 8px;
}

.location-search-results::-webkit-scrollbar-track,
.sic-code-search-results::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.location-search-results::-webkit-scrollbar-thumb,
.sic-code-search-results::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.location-search-results::-webkit-scrollbar-thumb:hover,
.sic-code-search-results::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.type-category-buttons {
  display: flex;
  margin-bottom: 15px;
  border-bottom: 1px solid #e4e4e4;
  padding: 0 15px;
}

.type-category-button {
  background: none;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  color: #666;
  position: relative;
  flex: 1;
  transition: color 0.3s;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.type-category-icon {
  font-size: 14px;
}

.type-category-button.selected {
  color: #1b8ccc;
}

.type-category-button.selected::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1b8ccc;
}

.type-category-button:hover {
  color: #1b8ccc;
}

.type-options {
  max-height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.type-options::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.mobile-text {
  display: none;
}

/* Date filters grid layout */
.dates-filter-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.dates-filter-grid .cp-explorer-filter-expanded-container {
  margin: 0;
}

@media (max-width: 480px) {
  .expanded-filter-wrapper {
    position: relative; /* Add position relative to wrapper */
  }

  .cp-explorer-filter-expanded-dropdown {
    font-size: 10px;
  }

  .cp-explorer-filter-expanded {
    gap: 5px;
  }

  .cp-explorer-filter-expanded-top {
    padding: 3px 5px;
  }

  .desktop-text {
    display: none;
  }

  .mobile-text {
    display: inline;
  }

  .dates-filter-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Update dropdown positioning to be relative to wrapper */
  .cp-explorer-filter-expanded-container {
    position: static;
  }

  .dropdown-options {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 1000;
  }

  /* Update search results positioning */
  .location-search-results,
  .sic-code-search-results {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 1000;
  }
}

@media screen and (max-width: 768px) {
  .cp-explorer-filter-expanded-dropdown .selected-option {
    margin: 0;
    padding: 0px 2px;
  }
}