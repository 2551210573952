.financials-title {
  margin-bottom: 20px;
  font-size: 25px;
  color: #0380C7;
  font-weight: 600;
}

.financials-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.financial-item {
  width: 45%;
  float: left;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.financial-item-label {
  font-size: 16px;
  display: block;
  font-weight: 600;
  text-decoration: underline;
}

.financial-item .value {
  margin-right: 10px;
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.text-info {
  color: #000 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-primary {
  color: #000 !important;
}

.view-financials-link {
  color: #416171;
  font-weight: 600;
}

.view-financials-link:hover {
  text-decoration: underline;
}

@media (min-width: 300px) and (max-width: 620px) {
  .financials-container {
    display: flex;
    flex-direction: column;
  }

  .financials-content {
    display: flex;
  }

  .financials-area-chart {
    width: 300px;
    height: 150px;
  }
}