.side-menu-layout {
    display: flex;
    height: 87vh;
    flex-direction: row;
  }
  
  .side-menu-content {
    flex: 1;
    overflow-y: auto;
  }
  
  /* Mobile view adjustments */
  @media (max-width: 768px) {
    .side-menu-layout {
      flex-direction: column; /* Stack elements vertically */
      height: auto; /* Allow height to adjust based on content */
    }

    .side-menu-content {
      order: 1; /* Ensure content is below the side menu */
    }
  }
  