.Garph-button.primary {
  border: 2px;
  display: flex;
  flex-direction: column;
  background: none;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(2, 156, 189);
  margin-bottom: 10px;
}

.button-text {
  color: black;
  font-size: 14.4px;
  font-weight: 400;
}