.burger-main {
  border: none;
  cursor: pointer;
  font-size: 25px;
  color: rgb(182, 178, 178);
}

.main-toolbar {
  display: flex;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
}

.main-toolbar-left-section {
  display: flex;
  align-items: center;
  gap: 20px;
}

.main-toolbar-page-title {
  margin-bottom: 0;
  font-weight: 500;
}

.custom-gradient-button {
  background-color: #fff;
  border: 2px solid rgb(2, 156, 189);
  width: 232px;
  height: 45px;
  border-radius: 300px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  position: relative;
}

.user-plan-button {
  background-color: #fff;
  border: 1px solid #d0d5dd;
  min-width: 160px;
  height: 45px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding: 2px;
}

.header-dropdown-native-userdropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-dropdown-native-userdropdown-initials {
  border-radius: 50%;
  margin-right: 10px;
  height: 50px;
  width: 50px;
  border: 2px solid transparent;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #0466d1, #00d1a9);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: inline-block;
  overflow: hidden;
}

.greetings-avatar {
  width: 45px;
  height: 45px;
  border: 1px solid #029cbd;
  border-radius: 50px;
}

@media (max-width: 600px) {
  .name-greetings {
    display: none;
  }

  .custom-gradient-button {
    display: none;
  }

  .user-plan-button {
    display: none;
  }

  .main-toolbar {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 0px;
  }

  .main-toolbar-page-title {
    font-size: 0.9rem;
  }
}

@media (max-width: 320px) {
  .greetings-avatar {
    display: none;
  }
}