.login-section {
    padding: 100px 40px 32px;
    background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
    height: 100vh;
  }
  
  .login-section .login-form-div {
    max-width: 500px;
    margin: auto auto;
    background-color: #f5f5f5;
    padding: 32px 40px;
    border-radius: 10px;
  }
  
  .login-section .logo-login {
    max-width: 220px;
    margin-bottom: 40px;
  }
  
  .login-section .login-form-div .input-outer-div .input-div .input-login {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    color: #212529;
    padding: 7px 17px;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 6px;
    outline: none;
  }
  
  .login-section .login-form-div .input-outer-div .input-div .remember-me-text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    color: #212529;
    border-radius: 8px;
    width: 100%;
    margin-left: 4px;
  }
  
  .login-section .forgot-pass-link {
    font-size: 0.75em;
    margin-top: -5px;
    margin-bottom: 15px;
  }
  
  .login-section .forgot-pass-link a {
    text-decoration: none;
    color: #0466d1;
  }
  
  .login-section .login-form-div .input-outer-div .label-text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875em;
    line-height: 1.5em;
    color: #021b41;
    margin: 10px 0;
  }
  
  .login-section .buttons-div {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
  
  .login-section .buttons-div .sign-in-btn-div {
    margin-bottom: 16px;
  }
  
  .login-section .buttons-div .or-div {
    margin-bottom: 16px;
  }
  
  .login-section .buttons-div .google-btn-div {
    margin-bottom: 16px;
  }
  
  .login-section .buttons-div .sign-in-btn-div button {
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid #0466d1;
    background-color: #0466d1;
    color: white;
    padding: 5px 24px;
    font-size: 1em;
  }
  
  .login-section .buttons-div .google-btn-div button {
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid #0466d1;
    background-color: white;
    color: #212529;
    padding: 5px 24px;
    font-size: 1em;
  }
  
  .login-section .buttons-div .google-btn-div button img {
    max-width: 20px;
    margin-right: 10px;
  }
  
  .login-section .bottom-login-div {
    margin-top: 40px;
  }
  
  .login-section .bottom-login-div p {
    font-weight: 600;
    font-size: 0.75em;
    line-height: 1.5em;
  }
  
  .login-section .bottom-login-div a {
    font-weight: 600;
    font-size: 0.75em;
    line-height: 1.5em;
    text-decoration: none;
    color: #0466d1;
  }

  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  
  @media (max-width: 575.99px) {
    .login-section .login-form-div {
      padding: 16px;
    }
  
    .login-section .buttons-div .google-btn-div button {
      /* font-size: 14px; */
    }
  
    .login-section .buttons-div .sign-in-btn-div button {
      /* font-size: 14px; */
    }
  }
  