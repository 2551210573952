.corporate-explore-insight-companies-container {
  width: 100%;
  border-radius: 8px;
  overflow-y: auto;
  background-color: #fff;
}

.corporate-explore-insight-companies-container-row {
  background-color: white;
}

/* WebKit scrollbar styles */
.corporate-explore-insight-companies-container::-webkit-scrollbar {
  width: 8px;
}

.corporate-explore-insight-companies-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.corporate-explore-insight-companies-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.corporate-explore-insight-companies-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.corporate-explore-insight-companies-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.corporate-explore-insight-companies-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #0380C7;
  display: flex;
  align-items: center;
}

.corporate-explore-insight-companies-name {
  width: 40%;
}

.corporate-explore-insight-companies-name p {
  margin: 0;
}

.corporate-explore-insight-company-icon {
  width: 20px;
  height: 20px;
  background-size: cover;
  margin-right: 8px;
}

.cei-status-active {
  background-color: rgb(22, 160, 134);
  color: #fff;
  font-size: 12px;
  padding: 4px 14px;
  border-radius: 4px;
  display: inline-block;
}

.corporate-explore-person-row-first {
  display: flex;
  justify-content: space-between;
}

.corporate-explore-person-row-first > * {
  width: 33%;
}

.corporate-explore-person-row-first > *:not(:first-child) {
  text-align: right;
}

.corporate-explore-insight-person-insights-container-topbar {
  display: flex;
  justify-content: space-between;
}

.corporate-explore-insight-person-insights-container-topbar h6 {
  width: 33%;
  font-size: 18px;
  font-weight: 700;
}

.corporate-explore-insight-person-insights-container-topbar h6 {
  width: 33%;
  font-size: 18px;
  font-weight: 700;
}

.corporate-explore-insight-person-insights-container-topbar h6:nth-of-type(n+2) {
  text-align: right;
}


.corporate-explore-insight-view-all-companies {
  font-size: 14px;
  color: #00355f;
  text-decoration: none;
}

.corporate-explore-insight-view-all-companies:hover {
  text-decoration: underline;
}

.corporate-explore-insight-company-name-link {
  color: #00355f;
}

.corporate-explore-insight-companies-network-buttons {
  display: flex;
  justify-content: flex-end;
}

.corporate-explore-insight-person-container {
  margin: 10px 0;
}

.corporate-explore-insight-person-container-mobile-view {
  margin: 10px 0;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  padding: 15px;
}

.corporate-explore-insight-person-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.corporate-explore-insight-person-row p {
  margin: 0;
}

.corporate-explore-insight-person-container p {
  margin: 0;
}

.corporate-explore-insight-company-name {
  color: #0466D1;
  font-weight: 600;
  font-size: 16px;
}

.corporate-explore-insight-company-sub-heading {
  color: '#101828';
  font-size: 14px;
  font-weight: 700;
}

@media (min-width: 360px) and (max-width: 400px) {
  .corporate-explore-insight-companies-network-buttons {
    display: flex;
    justify-content: flex-end;
  }
}