.contact-card {
  border-radius: 8px;
  margin-left: 10px;
}

.contact-card-title {
  margin-bottom: 20px;
  font-size: 25px;
  color: #0380C7;
  font-weight: 600;
}

.contact-card-map-container {
  width: 90%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
}

.contact-card-map-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-card-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.contact-card-item span {
  font-size: 10px;
  color: #475467;
  font-weight: 400;
}

.card-item-label span {
  font-size: 16px;
  font-weight: 600;
}

.contact-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.contact-item i {
  font-size: 18px;
  margin-right: 10px;
  color: #6c757d;
}

.contact-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.contact-info strong {
  font-weight: 600;
  color: #333;
  width: 40%;
}

.contact-info span {
  margin-left: 10px;
  width: 60%;
  text-align: left;
  color: #666;
}

.update-info-btn {
  display: block;
  margin: 5px 0;
  /* padding: 2px 0; */
  width: 20%;
  font-size: 12px;
  margin-left: 5px;
  color: #fff;
  background-color: #eff0f2;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  color: black;
  text-align: center;
}

.update-info-btn:hover {
  background-color: #5a6268;
}

.view-people-contacts {
  display: block;
  margin-top: 20px;
  font-size: 14px;
  color: #007bff;
  text-decoration: none;
  /* text-align: center; */
}

.view-people-contacts:hover {
  text-decoration: underline;
}