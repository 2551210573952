.sic-code-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.sic-icon {
    margin-right: 10px;
    height: 28px;
    width: 27px;
}

.sic-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.sic-table tr {
    background-color: white !important;
}

.sic-table th,
.sic-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.sic-table th {
    font-weight: bold;
    color: #666;
}

.sic-table td {
    color: #333;
}
