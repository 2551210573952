.up-record-list-container {
  background-color: #f6f6f7;
  padding: 15px;
}

.up-record-header {
  display: flex;
  padding: 12px;
  background: rgba(4, 102, 209, 0.05);
  font-weight: bold;
  border-radius: 6px;
  margin-bottom: 10px;
  color: rgba(3, 128, 199, 1);
  width: 100%;
  border-radius: 6px;
}

.up-column-title {
  width: calc(96%/4);
}

.up-record-header span:last-child {
  width: 4%;
}

/* Row Styling */
.up-record-row {
  display: flex;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  width: 100%;
}

.up-record-row:hover {
  background: #f4f4f4;
}

/* Items inside the row */
.up-record-item {
  text-align: left;
  font-size: 14px;
  width: calc(96%/4);
  border-right: 1px solid #EAECF0;
  padding: 1%;
}

.up-record-row span:last-child {
  width: 4%;
  border: none;
}

/* Link Styling */
.up-record-link {
  color: #007bff;
  text-decoration: none;
}

.up-record-link:hover {
  text-decoration: underline;
}

/* Eye Icon */
.up-icon {
  margin-left: 10px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {

  .up-record-header span:nth-child(n+3),
  .up-record-row span:nth-child(n+3) {
    display: none;
  }

  /* Make the first two columns take up 50% each */
  .up-record-header span:nth-child(-n+2),
  .up-record-row span:nth-child(-n+2) {
    width: 50%;
  }
}