h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.corporate-explore-keyData-company-description {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 21px;
}

.main-btns {
  display: flex;
  margin-bottom: 20px;
}

.corporate-explore-keyData-report-btns {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.corporate-explore-keyData-report-btn {
  color: #fff;
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  cursor: pointer;
  height: 27px;
  border-radius: 30px !important;
  border: none;
}

.corporate-explore-keyData-report-btn:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}

.corporate-explore-ccj-report-btn {
  color: #fff;
  background: linear-gradient(to left, #3b90d4, #48a3d3, #42adcd, #43bbc8, #78d9d1);
  cursor: pointer;
  width: 100%;
  border-radius: 8px !important;
  border: none;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 18px;
  padding: 8px 0;
}

.corporate-explore-keyData-upgrade-btn {
  color: #fff;
  background: linear-gradient(to left, #3b90d4, #48a3d3, #42adcd, #43bbc8, #78d9d1);
  cursor: pointer;
  border-radius: 8px;
  padding: 7px 20px;
  border: none;
  width: 32%;
  text-align: left;
  font-size: 12px;
}

.corporate-explore-keyData-upgrade-btn:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}


.corporate-explore-keyData-company-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 20px;
}

.info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.info-item div:first-child {
  display: flex;
  justify-content: space-between;
}

.info-item strong {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.info-item span {
  font-size: 16px !important;
  font-weight: 600;
}

.info-item small {
  font-size: 12px;
  color: gray;
}

.info-item-content {
  display: flex;
}

.info-item-content-row {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.info-item-content-row:first-child {
  border-right: 1px solid #EAECF0;
}

.info-item-content-row:last-child {
  padding-left: 15px;
}

.status-active {
  color: #16a086;
  display: inline-block;
  border: 2px solid;
  padding: 1px 8px;
  font-weight: 800;
  width: 30%;
  border-radius: 5px;
  font-size: 12px;
}

.status-submitted {
  color: rgb(0, 209, 169);
}

.corporate-explore-key-data-title {
  margin-top: 0px;
  font-size: 25px;
  color: #0380C7;
  font-weight: 600;
}

.corporate-explore-key-data-subtitle{
  font-size: 12px;
}

.corporate-explore-keyData-account-info {
  border-radius: 16px;
  padding: 16px 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.corporate-explore-keyData-account-info h5 span {
  float: right;
  color: #00D1A9;
  font-size: 14px;
  font-weight: normal;
}

.corporate-explore-keyData-confirm-statement {
  border-radius: 16px;
  padding: 16px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.corporate-explore-keyData-confirm-statement h5 span {
  float: right;
  color: #00D1A9;
  font-size: 14px;
  font-weight: normal;
}

@media (min-width: 300px) and (max-width: 620px) {
  .corporate-explore-keyData-company-info {
    display: flex;
    flex-direction: column;
  }

  .main-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .corporate-explore-keyData-report-btns {
    flex-direction: column;
  }

  .corporate-explore-keyData-report-btn {
    margin-bottom: 5px;
  }

  .corporate-explore-keyData-upgrade-btn {
    margin-bottom: 5px;
    margin-left: 0px;
    width: 100%;
    font-size: 15px;
  }

  .corporate-explore-ccj-report-btn {
    margin-bottom: 5px;
    margin-left: 0px !important;
  }
}

@media (max-width:480px) {
  .corporate-explore-key-data-title {
    font-size: 22px;
  }

  .corporate-explore-key-data-subtitle{
    font-size: 10px;
  }
}