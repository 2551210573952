.right-side-menu {
  position: fixed;
  top: 10%;
  right: -100%;
  width: 50%;
  height: 90%;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
}

.right-side-menu.open {
  right: 0;
}

.right-side-menu-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.right-side-menu-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: flex-end;
}

.right-side-menu-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.right-side-menu-close:hover {
  background-color: #f5f5f5;
}

.right-side-menu-body {
  padding: 20px;
  flex: 1;
  overflow-y: auto;
}

@media (max-width: 480px) {
  .right-side-menu {
    width: 100%;
    top: 30%;
    height: 70%;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .right-side-menu-header {
    padding: 5px 20px;
    border: none;
  }

  .right-side-menu-body {
    padding: 0;
  }
}