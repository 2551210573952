.corporate_explorer_filter_button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(92, 90, 90, 0.1);
  border: 1px rgb(165, 164, 164) solid;
}

.corporate_explorer_filter_button:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(92, 90, 90, 0.1);
  border: 1px rgb(99, 96, 96) solid;
  cursor: pointer;
}

.corporate_explorer_filter_button_text {
  text-align: center;
  height: 100%;
  font-size: 10px;
  padding: 5px;
  display: flex;
  gap: 5px;
  height: 50%;
  border: 1px rgb(165, 164, 164) solid;
  border-radius: 5px;
  cursor: pointer;
}

.corporate_explor_filter_modal_main {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 79vh;
  overflow: auto;
}

.corporate_explor_filter_modal_main_options {
  padding: 5px;
  flex: 0.2;
  background-color: rgb(248, 248, 248);
}

.corporate_explor_filter_modal_main_mid {
  display: flex;
  flex: 0.6;
  padding: 10px;
  width: 100%;
}

.corporate_explor_filter_modal_main_right {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  flex: 0.2;
  background-color: rgb(243, 243, 243);
  width: 100%;
  text-align: center;
}

.corporate_explorer_filter_options_sidebar_main {
  height: 100%;
  overflow: auto;
}

@media (max-height: 640px) {
  .corporate_explor_filter_modal_main {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 77vh;
    overflow: auto;
  }
}