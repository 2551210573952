.coporate-Insights-Document-container {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
  position: relative;
}

a,
font,
input,
td,
div,
select {
  font: 13px;
  outline: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  text-size-adjust: none;
}

.tiles-x {
  display: none;
  right: 0;
  top: 2px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  border: none !important;
  animation-name: fadeInUp;
  animation-duration: 270ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  z-index: 3;
}

.c-close-icon {
  background-repeat: no-repeat;
  height: 16px;
  width: 21px;
  display: inline-block;
  vertical-align: middle;
  /* background: url(img/icons.png) no-repeat; */
  background-position: -53px -409px;
  width: 14px !important;
}

.insight-drag-area .contentcoporate-Insights-Document-content > .title {
  font-size: 18px !important;
}

.insight-drag-area .title {
  position: relative;
  z-index: 1;
  cursor: move;
}

.coporate-Insights-Document-content > .title {
  font-size: 16px;
  border: 0;
  margin-top: 6px;
}

div {
  display: block;
  unicode-bidi: isolate;
}
.coporateInsights-Document-count {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 5px;
  margin-left: 6px;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  vertical-align: top;
  background: #656e73;
}
.coporate-Insights-Document {
  font-size: 18px !important;
      color: #021B41;

}
.coporate-Insights-Document-grey-button {
  padding: 4px 12px;
  text-decoration: none !important;
  white-space: nowrap;
  font-size: 9pt;
  border-radius: 3px;
  color: #000 !important;
  text-align: center;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.21);
  font-weight: 700 !important;
  border: 0;
  border-radius: 50px !important;
  background: #eff0f2;
  color: #444548;
  cursor: pointer;
  /* box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.21); */
}

.striped-table tr td {
  padding: 11px;
  vertical-align: top;
  font: 13px;
  outline: none;
  text-shadow: 1px 1px 1px rgba(65, 64, 64, 0.004) !important;
  /* text-rendering: optimizeLegibility !important; */
  text-size-adjust: none;
}

.coporate-Insights-Document-incorporation-container{
  display: flex;
  justify-content: space-between;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
}

.coporate-Insights-Document-incorporation{
  font-size: 16px;
  font-weight: 600;
}

.desc {
  font-size: 14px;
}

.coporate-Insights-Document-link-button {
  color: #416171;
  font-weight: 600;
}

.coporate-Insights-plan-btn{
  font-size: 12px;
  font-weight: 400;
  color: #0380C7;
  border-left: 1px solid #EAECF0;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

/* Custom scrollbar styling */
.coporate-Insights-Document-container::-webkit-scrollbar {
  width: 8px;
}

.coporate-Insights-Document-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.coporate-Insights-Document-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.coporate-Insights-Document-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
