.up-saved-items-container {
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  font-family: Arial, sans-serif;
}

.up-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile-dashboard-title {
  font-size: 1.5em;
  margin-bottom: 0px;
  font-weight: bold;
}

.up-record-list-container {
  width: 100%;
  margin: 20px 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

/* Tabs Styling */
.up-tabs {
  display: flex;
  width: 100%;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.up-tabs button:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.up-tabs button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.up-tab {
  background: none;
  border: 1px solid #D0D5DD;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  color: #333;
  width: 20%;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px
}

.up-tab.active {
  background-color: #f4f5f7;
}

.up-tab:hover {
  color: #007bff;
}

.up-dropdown-button {
  background: none;
  border: 1px solid #D0D5DD;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  color: #333;
  width: 100%;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 768px) {
  .up-saved-items-container {
    margin: 0;
  }

  .up-tabs {
    flex-direction: column;
  }

  .up-tab {
    width: 100%;
    justify-content: flex-start;
    background-color: #f5f5f5;
  }

  .up-tabs button:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .up-tabs button:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 0px;
  }
}