.corporate-explore-details-container {
  background-color: #fff;
  /* border: 1px solid #e0e0e0; */
  border-radius: 4px;
  width: 100%;
}

.corporate-explore-details-title {
  font-size: 24px;
  color: #0380C7;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 12px;
  font-weight: 600;
}

.corporate-explore-details-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.corporate-explore-details-icon {
  margin-right: 8px;
  color: #333;
}

.corporate-explore-details-label {
  font-weight: bold;
  margin-right: 12px;
  flex: 1;
  min-width: 120px;
  color: #333;
}

.corporate-explore-details-value {
  color: #666;
}
