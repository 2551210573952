.cesearch-entites-advanced-filter-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.cesearch-entites-advanced-filter-container.show {
  transform: translateY(0);
  opacity: 1;
}

.cesearch-entites-advanced-filter {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.ce-search-entities-close-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: rgb(80, 80, 80);
}

.ce-search-entities-close-button:hover {
  color: #000000;
}

.advance-search-heading {
  font-weight: 600;
}

.filter-label {
  font-size: 16px;
  font-weight: 600;
}

.company-person-advance-filter-form-control {
  height: 48px !important;
  border-radius: 16px !important;
}


.ce-search-entities-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 2%;
}

.ce-search-entities-pagination-button {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.ce-search-entities-pagination-button:not(.prev-next) {
  background: #ccc;
  color: white;
}

.ce-search-entities-pagination-button.active:not(.prev-next) {
  background: linear-gradient(to bottom right, #00D1A9, #0466D1);
}

.ce-search-entities-pagination-button.prev-next {
  background: white;
  color: black;
  margin-left: 20px;
  margin-right: 20px;
}

.ce-search-entities-pagination-button.prev-next:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.ce-search-entities-pagination-button.prev-next:hover:not(:disabled) {
  color: black;
}

.ce-search-entities-pagination-button:not(.prev-next):hover:not(:disabled) {
  transform: scale(1.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.ce-search-entities-pagination-button:disabled {
  cursor: not-allowed;
}


.cesearch-entites-dashboard-content {
  display: flex;
  flex-direction: column;
}

.cesearch-entites-explorer-link-section {
  padding: 15px;
  display: flex;
  justify-content: center;
  margin: 10px;
}

.autocomplete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cp-explorer-section-table {
  margin-top: 40px;
  overflow-x: auto;
}

.cesearch-entites-search-container {
  position: relative;
  width: 50%;
}

.cesearch-entites-search-input {
  width: 97.5%;
  padding: 6px;
  font-size: 16px;
  border-radius: 20px;
}

.cesearch-entites-search-input::placeholder {
  color: #8A8A8A;
  /* Default placeholder color */
}

.placeholder-red::placeholder {
  color: red !important;
  /* Red placeholder when isDottedCardClicked is true */
}

.custom-card-spacing {
  padding: 10px 30px !important;
}

.cesearch-entites-dropdown-main {
  background: white;
  margin-top: 1px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-top: 2px solid #cec9c9;
}

.sidebar-toggle:hover {
  background-color: #0056b3;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #6b6c6d;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #838282;
}

.sidebar-recent-search {
  position: absolute;
  width: 250px;
  background-color: #fff;
  height: 87vh;
  z-index: 99;
  padding-right: 10px;
  padding-left: 10px;
  top: 0;
  border-right: 1px solid rgb(2, 156, 189);
  transition: transform 0.3s ease;

}


/* .sidebar-recent-search>div:first-child {
  margin-top: 20px !important;
} */

.sidebar-search-item-container {
  height: 90%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.sidebar-search-item-container::-webkit-scrollbar {
  display: none;
}

.close-icon-recent-search {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px !important;
  align-items: center;
  border-radius: 50%;
  z-index: 1000;
  transition: transform 0.3s ease;
  background-color: #fff !important;
  margin-top: 0 !important;
}

.close-icon-recent-search:hover {
  background-color: #fff !important;
}

.sidebar-recent-search-footer {
  position: absolute;
  bottom: 0;
  width: 95%;
  text-align: center;
  background-color: #fff;
}

.sidebar-recent-search-footer button{
  width: 90%;
  border-radius: 65px;
  padding: 6px;
  font-size: 16px;
  color: #0466D1;
  font-weight: 600;
  border: none;
  margin: 10px;
}

.history-item {
  padding: 10px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #EAECF0;
}

.history-item:hover {
  background-color: #ddd;
}

.toggle-button {
  position: fixed;
  left: 50px;
  top: 13vh;
  width: 34px;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4a9be7;
  border-radius: 18px;
  /* Changed from 50% to fixed value */
  transition: all 0.3s ease;
  /* Changed from just left to all properties */
  z-index: 10;
  background-color: white;
  overflow: hidden;
}

.toggle-button img {
  position: absolute;
  left: 8px;
}

.toggle-button .button-text {
  opacity: 0;
  white-space: nowrap;
  padding-left: 30px;
  /* Space for the icon */
  color: rgb(2, 156, 189);
  font-weight: 500;
  font-size: 14px;
  transition: opacity 0.3s ease;
}

.toggle-button:hover {
  width: 180px;
  /* Width when expanded */
  justify-content: flex-start;
  padding-left: 8px;
}

.toggle-button:hover .button-text {
  opacity: 1;
}

.cesearch-entites-buttons-container {
  display: flex;
  padding: 8px;
  width: 15rem;
}

.cesearch-entites-container {
  margin-top: 30px;
}

.cesearch-entites-main-container {
  margin: auto;
  width: 50%;
}

.cesearch-entites-dropdown-button {
  flex: 1;
  padding: 6px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background: #ffff;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cesearch-entites-dropdown-button :hover {
  background: #e0e0e0;
}

.cesearch-entites-dropdown-button.active {
  background: #d0d0d0;
}

.cesearch-entites-heading {
  padding-top: 50px !important;
  margin-left: 20px;
}

.cesearch-entites-main-cards {
  padding: 10px;
}

.collection-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.collection-item:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.collection-icon {
  margin-right: 10px;
}

.results-dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  padding: 10px;
}

.results-heading {
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.result-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.result-item:last-child {
  border-bottom: none;
}

.dropdown-button.active {
  background-color: #ccc;
  /* Adjust color as needed for active state */
  font-weight: bold;
  border-radius: 20px;
}

.cesearch-entites-main-cards-header {
  font-weight: 400;
  font-size: 16px;
  display: flex;
}

.cesearch-entites-btn {
  float: right;
  width: 3rem;
  height: 2rem;
  background-color: #6aa8f0;
  border-radius: 5px;
  border: none;
}

.cesearch-entites-main-btns {
  /* padding-top: 10px; */
  padding: 20px !important;
}

.add-button {
  display: block;
  /* margin-top: 20px !important; */
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  /* position: absolute; */

  bottom: 20px;
}

.add-button:hover {
  background-color: #0056b3;
}

.cesearch-entites {
  float: right;
  width: 80px;
  height: 60px;
}


.cesearch-entites-main-All-cards {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Adds vertical scrollbar */
  max-height: 40vh;
  /* Adjust height as needed */
  padding: 10px;
  /* Optional padding for spacing */
  gap: 10px;
  /* Optional gap between cards */
}

/* Scrollbar styling */
.cesearch-entites-main-All-cards::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.cesearch-entites-main-All-cards::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background color of the scrollbar track */
}

.cesearch-entites-main-All-cards::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 6px;
  /* Roundness of the scrollbar thumb */
  border: 3px solid #f1f1f1;
  /* Padding around the scrollbar thumb */
}

.cesearch-entites-main-All-cards::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar thumb when hovered */
}

.cesearch-entites-card {
  min-height: 100px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ec-search-entites-explore-link-officer-card-main {
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: auto;
}

.ec-search-entites-explore-link-officer-card {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.cesearch-filter {
  display: flex;
  margin-right: 10px;
  justify-content: flex-end;
}

.cesearch-filter:hover {
  text-decoration: underline;
  color: #007bff;
}

.advance-search-container {}

.cesearch-entites-advanced-filter {
  padding-left: 7%;
  padding-right: 7%;
}

@media (min-width: 100px) and (max-width: 768px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px);
  }

  .cesearch-entites-search-container {
    position: relative;
    width: 100%;
  }

  .ec-search-entites-explore-link-officer-card-main {
    display: flex;
    flex-direction: column;
  }

  .ec-search-entites-explore-link-officer-card {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* .cesearch-entites-dropdown-main {
    background: white;
    padding-top: 14px;
    margin-bottom: 1px;
    border-bottom: 2px solid #cec9c9;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
  } */
  .cesearch-entites-explorer-link-section {
    margin: 1px;
  }

  .advance-search-heading {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .cesearch-entites-search-input {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px);
  }

  .ec-search-entites-explore-link-officer-card-main {
    display: flex;
    flex-direction: column;
  }

  .ec-search-entites-explore-link-officer-card {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1000px) and (max-width: 1600px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px);
  }
}

@media (max-width: 430px) {
  .cesearch-entites-advanced-filter-inner-container {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .cesearch-entites-advanced-filter-inner-container::-webkit-scrollbar {
    display: none;
  }

  /* For WebKit browsers like Chrome, Safari, and Edge */
  .cesearch-entites-advanced-filter::-webkit-scrollbar {
    display: none;
  }

  .toggle-button {
    left: 10px;
  }

  .ce-search-entities-pagination-container{
    margin-bottom: 15%;
  }
}