.up-profile-plan-container {
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  font-family: Arial, sans-serif;
}

.up-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-profile-dashboard-title {
  font-size: 1.5em;
  margin-bottom: 0px;
  font-weight: bold;
}

.user-plan-pricing-container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.user-plan-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

.user-plan-card.selected {
  border: 2px solid #a855f7;
  background-color: #f9f5ff;
}

.user-plan-hr.selected {
  color: #a855f7;
}

.user-plan-icon {
  background-color: #F4EBFF;
  padding: 2px 6px;
  border-radius: 50px;
}

.user-plan-header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 18px;
  padding: 0 15px;
}

.user-plan-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #a855f7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #a855f7;
}

.user-plan-card.selected .radio {
  background-color: #a855f7;
  color: white;
}

.user-plan-title {
  flex: 1;
}

.user-plan-price {
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
  padding: 0 15px;
}

.user-plan-price span {
  font-size: 14px;
  font-weight: normal;
  color: #666;
}

.user-plan-description {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .up-profile-plan-container {
    margin: 0;
  }
}