.corporate-explore-person-insights-bio {
  width: 100%;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  background-color: #fff;
}

.cei-person-icon svg {
  font-size: 40px;
  color: #333;
}

.corporate-explore-insights-person-name {
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: bold;
  margin: 0 ;
  text-align: left;

}

.corporate-explore-insights-person-details {
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: #555;
  margin: 0;
  text-align: center;
}

.corporate-explore-insights-person-icon {
  text-align: center;
}

.corporate-explore-insights-person-report-btn {
  display: none;
}

@media (max-width: 600px) {
  .corporate-explore-insights-person-report-btn {
    display: block;
    color: #fff;
    background: linear-gradient(to left, #3b90d4, #48a3d3, #42adcd, #43bbc8, #78d9d1);
    cursor: pointer;
    width: 100%;
    border-radius: 8px !important;
    border: none;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 18px;
    padding: 8px 0;
  }
}