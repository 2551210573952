.corporate-explore-insight-appointments-container {
  background-color: transparent;
  border: none;
  width: 100%;
}

.corporate-explore-insight-appointments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.corporate-explore-insight-appointments-title {
  font-size: 24px;
  font-weight: 600;
  color: #0380C7;
  margin: 0;
}

.corporate-explore-insight-appointments-icon {
  color: #0380C7;
  height: 34px;
  width: 40px;
  margin-right: 10px;
}

.corporate-explore-insight-appointments-report-btn {
  color: #fff;
  background: linear-gradient(to left, #3b90d4, #48a3d3, #42adcd, #43bbc8, #78d9d1);
  cursor: pointer;
  width: 40%;
  border-radius: 8px !important;
  border: none;
  font-size: 18px;
  padding: 8px 0;
  margin: 0;
  display: block;
}

.corporate-explore-insight-appointments-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  flex-direction: column;
  width: 100%;
}

.corporate-explore-insight-appointments-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.corporate-explore-insight-appointments-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.corporate-explore-insight-appointments-stats span {
  display: block;
  font-size: 16px;
  color: #555;
  margin-top: 4px;
  font-weight: 600;
}

.corporate-explore-insight-appointments-stats h2 {
  margin-top: 8px;
  margin-bottom: 4px;
  margin-left: -4px;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  width: 4%;
  text-align: center;
}

.corporate-explore-insight-appointments-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 8px;
}

.ce-stat-active {
  border-left: 4px solid #4caf50;
  padding-left: 5px;
}

.ce-stat-resigned {
  border-left: 4px solid #f44336;
  padding-left: 5px;
}

.ce-stat-dissolved {
  border-left: 4px solid #d32f2f;
  padding-left: 5px;
}

@media (max-width: 600px) {
  .corporate-explore-insight-appointments-report-btn {
    display: none;
  }
}