.content-container {
  width: 80%;
  height: 72vh;
  /* padding: 1rem; */
}

.corporate-links-flow-section-top-container {
  width: 70%;
}

.scrollable-content-container {
  overflow-y: auto;
  -ms-overflow-style: none;
}

.scrollable-content-container::-webkit-scrollbar {
  display: none;
}

.content-container-big {
  width: 90%;
  padding: 1rem;
}

.corporate-links-flow-section-add-entity-button-holder {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 10px;
  justify-content: start;
  align-items: left;
}

.flowSection-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.flowSection-card-item {
  flex: 1 1 calc(33.333% - 16px);
  /* Ensures 3 cards per row */
  max-width: calc(33.333% - 16px);
  /* Prevents cards from exceeding 3 per row */
  box-sizing: border-box;
  /* Include padding and border in width calculations */
}

@media(max-width: 1200px) {
  .content-container {
    width: 80%;
  }

  .corporate-links-flow-section-top-container {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 992px) {
  .content-container {
    width: 90%;
  }

  .corporate-links-flow-section-top-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .content-container {
    width: 100%;
    padding: 1rem 0;
    padding-bottom: 10%;
  }

  .corporate-links-flow-section-top-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .corporate-links-flow-section-add-entity-button-holder {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    width: 100%;
    /* align-items: left; */
  }

  .corporate-links-flow-section-search-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .flowSection-card-item {
    flex: 1;
    /* Ensures 1 cards per row */
    min-width: 100%;
    /* Prevents cards from exceeding 1 per row */
    box-sizing: border-box;
    /* Include padding and border in width calculations */
  }
}

.corporate-links-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.cesearch-entites-flow-section-addentity-card {
  display: flex;
  border: 1px dotted blue;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  width: 200px;
  justify-items: center;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
}

.cesearch-entites-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  /* Top shadow */
}

.cesearch-entites-flow-section-addentity-card:hover {
  background-color: gainsboro;
}

.corporate-links-main-content-buttons-container-entity-button {
  margin: 5px;
  padding: 10px 20px;
}

.corporate-links-main-content-buttons-container-entity-button {
  background-color: #0466d1;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
}

.corporate-links-main-content-Text {
  padding: 20px !important;
  margin-left: 10px;
}

.corporate-links-main-content-type-text {
  font-weight: 400px;
  font-size: 16px;
}

.corporate-links-main-content-buttons-container {
  margin-top: 15px;
  margin-left: 10px;
}

.corporate-links-flow-section-add-entity {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected-entites-button {
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px 15px;
  margin-top: 10px;
  margin-right: 8%;
}

.card-wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.5);
  /* Red overlay with opacity */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
  /* Initially hidden */
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.card-wrapper:hover .overlay {
  opacity: 1;
  border-radius: 15px;
  /* Show overlay on hover */
}


input:disabled::placeholder {
  color: #aaa;
}

input:not(:disabled)::placeholder {
  color: #333;
}

.people-officers-count {
  display: flex;
  height: 16px;
  background-color: #0466d1;
  color: white;
  font-size: 11px;
  align-items: center;
  border-radius: 3px;
  justify-content: center;
  margin-left: 5px;
  padding: 3px;
  cursor: pointer;
  text-decoration: underline;
}

.cesearch-entites-flow-section-probelabel {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 50%;
}

.ec-search-entites-explore-link-officer-card-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 94%;
}

.ec-search-entites-explore-link-officer-card {
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.explore-link-officer-card-item {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
}

.ec-search-entites-explore-link-officer-mock-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 140px;
  border: 2px dashed #d3d3d3;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 1%;
}

.ec-search-entites-explore-link-officer-mock-card-container-mobile {
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 140px;
  border: 2px dashed #d3d3d3;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 1%;
  display: none;
}

.ec-search-entites-explore-link-officer-mock-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.card-icon img {
  width: 70px;
  /* Adjust as necessary */
  height: 70px;
}

.card-text {
  font-size: 12px;
  color: #a1a1a1;
  /* Light gray text */
}

.corporate-links-flow-section-create-network {
  display: flex;
  justify-content: flex-start;
}

.text-black {
  color: rgb(221, 51, 51);
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 10px;
}

.cesearch-entites-footer-button {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 95%;
  height: 80px;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  /* Top shadow */
}

.ce-flow-section-search-results-mob-screen {
  display: none;
}

.ce-flow-section-footer-popup {
  display: none;
}

/* .corporate-explore-links-main-entity {
  display: 'flex';
  justify-content: 'center';
  flex-direction: 'column';
  align-items: 'center';
} */

@media (min-width: 260px) and (max-width: 768px) {
  .ec-search-entites-explore-link-officer-card-main {
    display: flex;
    flex-direction: column;
  }

  .ec-search-entites-explore-link-officer-card {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-top: 2px;
    margin-bottom: 6%;
    width: 100%;
  }

  .ec-search-entites-explore-link-officer-mock-card-container {
    display: none;
  }

  .ec-search-entites-explore-link-officer-mock-card-container-mobile {
    display: flex;
    margin: 5px;
  }

  .cesearch-entites-flow-section-probelabel {
    width: 100%;
  }

  .cesearch-entites-flow-section-addentity-card {
    width: 100px;
    padding: 10px;
  }

  .cesearch-entites-flow-section-addentity-card-create-network {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  .cesearch-entites-flow-section-probelabel {
    display: none;
    margin-top: 2px;
  }

  .flowSection-card-container {
    height: 50vh;
    overflow-y: auto;
    -ms-overflow-style: none;
  }

  .flowSection-card-container::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 508px) {
  .selected-entites-button {
    display: none;
  }

  .corporate-links-flow-section-add-entity {
    display: flex;
    justify-content: center;
  }

  .corporate-links-flow-section-search-container {
    gap: 10px
  }

  .flowSection-card-item {
    flex: auto;
    padding: 0 5px;
  }

  .cesearch-entites-footer-button {
    display: block;
  }

  /* 
  .cesearch-entites-footer {
    display: none;
  } */

  .flowSection-top-button {
    display: none;
  }

  .ec-search-entites-explore-link-officer-card-main {
    width: 100%;
    margin-bottom: 10%;
  }

  .ce-flow-section-search-results {
    display: none;
  }

  .ce-flow-section-search-results-mob-screen {
    display: block;
  }

  .ce-flow-section-footer-popup {
    display: flex;
  }

  .ec-search-entites-explore-link-officer-mock-card-container {
    display: none;
  }

  .ec-search-entites-explore-link-officer-mock-card-container-mobile {
    display: flex;
    margin: 5px;
  }
}

@media (min-width: 500px) and (max-width: 1000px) {
  .ec-search-entites-explore-link-officer-card-main {
    display: flex;
    flex-direction: column;
  }

  .ec-search-entites-explore-link-officer-card {
    display: flex;
    flex-direction: column;
  }

  .ec-search-entites-explore-link-officer-mock-card-container {
    display: none;
  }

  .ec-search-entites-explore-link-officer-mock-card-container-mobile {
    display: flex;
    margin: 5px;
  }
}