.corporate-explore-insight-person-insights-container {
  width: 100%;
  border-radius: 8px;
  overflow-y: auto;
}

/* WebKit scrollbar styles */
.corporate-explore-insight-person-insights-container::-webkit-scrollbar {
  width: 8px;
}

.corporate-explore-insight-person-insights-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.corporate-explore-insight-person-insights-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.corporate-explore-insight-person-insights-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.corporate-explore-insight-person-insights-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.corporate-explore-insight-person-insights-title {
  margin-bottom: 15px;
  color: #0380C7;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.corporate-explore-insight-person-insights-topbar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.corporate-explore-insight-person-insights-topbar h6 {
  font-size: 16px;
  font-weight: 700;
  flex: 1;
}

.corporate-explore-insight-person-insights-topbar h6:first-child {
  text-align: left;
}

.corporate-explore-insight-person-insights-topbar h6:last-child {
  text-align: right;
}

.corporate-explore-insight-person-insights-main {
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  justify-content: flex-end;
  transition: background-color 0.3s ease;
}

.corporate-explore-insight-person-insights-icon {
  color: #888;
  margin-right: 10px;
}

.corporate-explore-insight-person-insights-person-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.corporate-explore-insight-person-insights-person-detail-person-name {
  display: flex;
  font-size: 12px;
}

.corporate-explore-insight-person-insights-main:last-child {
  margin-bottom: 0;
}

.corporate-explore-insight-person-insights-person-detail-person-main-div {
  display: flex;
  cursor: pointer;
}