.corporate-explore-insight-container-logo {
  position: relative;
}

.top-right-badge,
.top-right-badge-dissolved {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.top-right-badge {
  color: #00D1A9;
}

.top-right-badge-dissolved {
  color: red;
}

.top-right-badge::before,
.top-right-badge-dissolved::before {
  content: "•";
  margin-right: 5px;
  font-size: 25px;
}

.top-right-badge::before {
  color: #00D1A9;
}

.top-right-badge-dissolved::before {
  color: red;
}

@media (min-width: 100px) and (max-width: 992px) {
  .corporate-explore-insight-container-logo {
    display: flex;
    /* Display when within the specified range */
    justify-content: flex-start;
    align-items: center;
  }
}

.corporate-explore-insight-company-Logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company-logo {
  width: 80px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
  /* Space between logo and company name */
}

.corporate-explore-insight-company-Name {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -1px;
  color: #000;
}

.corporate-explore-insight-company-Address {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  text-align: center;
}