.cp-explorer-section {
  padding: 15px;
}

.cp-explorer-banner-image {
  width: 100%;
  height: 50vh;
  margin-bottom: 3%;
  background-image: linear-gradient(to bottom right, #021b4192, #0467d18b),
    url('../../assets//images/cp-Search-Image.jpg');
  background-size: cover;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.cp-explorer-banner-image h3 {
  font-size: 28px;
  font-weight: 600;
}

.cp-explorer-banner-image h1 {
  font-size: 58px;
  font-weight: 600;
}

.cp-explorer-banner-image p {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}

.cp-explorer-filterreset-button-text {
  font-size: 10px;
}

.cp-explorer-filterreset-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
  width: 60px;
  cursor: pointer;
  height: 86%;
}

.cp-explorer-filterreset-button:hover {
  background-color: aliceblue;
}

.cp-explorer-section-filter {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.cp-explorer-right-section {
  display: flex;
  gap: 10px;
}

.cp-explorer-right-search-container {
  padding: 10px;
  border: 1px solid grey;
  height: 52%;
  border-radius: 5px;
}

.cp-explorer-right-search-input {
  border: none;
  background: none;
}

.cp-explorer-left-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cp-explorer-left-section h6 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
}

.cp-explorer-left-section p {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}


.autocomplete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cp-explorer-section-table {
  margin-top: 40px;
  overflow-x: auto;
}

@media (min-width: 360px) and (max-width: 768px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px);
    /* Use viewport width for flexibility */
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px);
    /* Use viewport width for flexibility */
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .cp-explorer-section-table {
    max-width: calc(100vw - 50px);
    /* Use viewport width for flexibility */
  }
}

@media (max-width: 768px) {
  .cp-explorer-banner-image h3 {
    font-size: 20px;
  }

  .cp-explorer-banner-image h1 {
    font-size: 40px;
  }

  .cp-explorer-banner-image p {
    font-size: 18px;
  }

  .cp-explorer-section-filter {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .cp-explorer-banner-image h3 {
    font-size: 16px;
  }

  .cp-explorer-banner-image h1 {
    font-size: 32px;
  }

  .cp-explorer-banner-image p {
    font-size: 14px;
  }

  .cp-explorer-left-section h6 {
    font-size: 16px;
  }

  .cp-explorer-left-section p {
    font-size: 12px;
  }

  .cp-explorer-section {
    padding: 0;
  }

  .cp-explorer-right-search-container {
    flex: 1;
    padding: 5px 10px;
  }
}