.up-update-password-container {
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background: #ffffff;
    font-family: Arial, sans-serif;
}

.up-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-profile-dashboard-title {
    font-size: 1.5em;
    margin-bottom: 0px;
    font-weight: bold;
}

.up-edit-button {
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
}

.up-edit-button:hover {
    background: #f0f0f0;
}

.top {
    display: block;
}

.bottom {
    display: none;
}

.up-input-group {
    margin-bottom: 15px;
    display: flex;
    width: 70%;
    gap: 5%;
    align-items: center;
}

.up-input-group label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    width: 30%;
}

.up-input-group input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: border 0.3s ease;
}

.up-button-group {
    width: 70%;
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.up-cancel-button {
    background-color: #fff;
    padding: 5px 14px;
    border-radius: 10px;
    border: 1px solid #D0D5DD;
}

.up-save-button {
    background-color: #00D1A9;
    padding: 5px 14px;
    border-radius: 10px;
    border: 1px solid #00D1A9;
    color: #fff;
}

.up-input-group input:disabled {
    background-color: #f0f0f0;
    color: #888;
}

.up-input-group input:focus {
    border-color: #007bff;
    outline: none;
}

.up-save-button.disabled {
    background-color: #f0f0f0;
    color: #888;
    cursor: not-allowed;
    border: 1px solid #f0f0f0;
}

@media screen and (max-width: 768px) {
    .up-update-password-container {
        margin: 0;
    }

    .up-input-group {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .up-input-group label {
        width: 100%;
    }

    .top {
        display: none;
    }

    .bottom {
        display: block;
    }

    .up-button-group {
        width: 100%;
    }
}