.watch-list-card {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icon {
  font-size: 48px;
  color: #ffcc00;
  margin-right: 20px;
}

.content {
  flex: 1;
  position: relative;
  margin-left: 20px;
}

.watch-list-header {
  font-size: 24px;
  margin: 0 0 10px 0;
  font-weight: 400;
  /* Margin below the header for spacing */
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  margin: 10px 0;
  color: #666;
}

.watch-list-button {
  background: linear-gradient(99.78deg, #0466d1 0%, #00d1a9 152.3%);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 14px;
  cursor: pointer;
}

.watch-list-button:disabled {
  color: #8a8a8a;
  background: #CFCFCF;
  cursor: not-allowed;
  border: none;
}